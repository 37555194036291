import { useLocation } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import { parse as queryParse } from "query-string"
import React, { useEffect } from "react"
import { scroller } from "react-scroll"
import Advantage from "../components/common/Advantage-box/Advantage"
import Block from "../components/common/Block/Block"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import IntroCard from "../components/common/IntroCard/IntroCard"
import Testimonial from "../components/common/testimonial-component/testimonial"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import Slide from "../components/utility/slide_content"
import "../styles/features.scss"

function FeatureDetailsV1({ pageContext }) {
  const { feature } = pageContext
  const location = useLocation()
  const searchParams = queryParse(location.search)
  const getScrollId = location.hash

  const ctaLinkProps = {
    className: "Layout-container",
    maxDescriptionWidth: "500px",
    lgLeft: 7,
    lgRight: 4,
    rightStyles: { alignItems: "center" },
  }
  const ctaCardProps = {
    bgPattern: "true",
    className: "Layout-max",
    maxDescriptionWidth: "475px",
    maxBoxWidth: "1400px",
    maxRowWidth: "1176px",
    lgLeft: 5,
    lgRight: 7,
  }

  const makeSlug = str => {
    var string = ""
    if (!!str) {
      string = str.replace(/\s+/g, "-").toLowerCase()
    }

    return string
  }

  useEffect(() => {
    if (!!searchParams.block) {
      setTimeout(function() {
        document.getElementById(`${searchParams.block}-link`).click()
      }, 2000)
    }
    if (getScrollId !== null) {
      scroller.scrollTo(getScrollId)
    }
  })

  useEffect(() => {
    const pageBlockId = sessionStorage.getItem("pageBlockScrollId")
    if (pageBlockId) {
      const pageBlk = document.getElementById(pageBlockId)
      window.scrollTo({
        top: pageBlk.offsetTop - 150,
        behavior: "smooth",
      })
      sessionStorage.removeItem("pageBlockScrollId")
    }
  }, [])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentFeatureDetailsV1 {
            SuperOps {
              pages(where: { title: "Feature Details" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock } = page

              return (
                <div className="feature-details">
                  <Frame seo={feature.seos[0]}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="About us"
                        // color={feature.heroBgColor}
                      />
                    </header>
                    <section>
                      <Hero
                        background={feature.heroBgColor}
                        tagName="features"
                        tag={feature.featureName}
                        heading={feature.heroHeading}
                        maxHeadingWidth="500px"
                        maxDescriptionWidth="500px"
                        description={feature.heroExcerpt}
                        primaryButtonText="GET STARTED FOR FREE"
                        primaryButtonLink="/signup"
                        secondaryButtonText="BOOK A DEMO"
                        secondaryButtonLink="/demo"
                        image={feature.heroImage.url}
                        maxImageWidth="500px"
                        marginBottom="-160px"
                      />
                    </section>

                    {feature.featureSlug === "intelligent-alerting" &&
                      !feature.benefitsTitle && (
                        <>
                          <IntroCard
                            html={feature.introCardText.html}
                            title={feature.introCardTitle}
                          />
                        </>
                      )}

                    <section className="section-wrapperr">
                      {feature.components.map((field, ind) => {
                        switch (field.__typename) {
                          case "SuperOps_Benefit": {
                            return (
                              <Advantage
                                title={field.benefitsTitle}
                                heading={field.benefitsHeading}
                                icon={field.benefitsIcon}
                                description={field.benefitsExcerpt}
                              />
                            )
                          }
                          case "SuperOps_Testimonial": {
                            return (
                              <Testimonial
                                marginTop="80px"
                                marginBottom="60px"
                                type="single-2"
                                background={field.testimonialBgColor}
                                testimonial={field.testimonialDescription[0]}
                                guestName={field.testimonialName[0]}
                                guestTitle={field.testimonialTitle[0]}
                                wireAnimation
                              />
                            )
                          }
                          case "SuperOps_Block": {
                            return (
                              <div id={`pageBlk-${ind + 1}`}>
                                <Block
                                  html={field?.blockContent?.html}
                                  imageTitle={field.blockImageTitle}
                                  image={field.blockImage}
                                  isImage={field.blockIsIllustration}
                                  isVideo={field.blockIsVideo}
                                  lgLeft={5}
                                  lgRight={7}
                                />
                              </div>
                            )
                          }
                          case "SuperOps_Advantage": {
                            return (
                              <Advantage
                                icon={field.advantagesIcon}
                                heading={field.advantagesHeading}
                                description={field.advantagesExcerpt}
                              />
                            )
                          }
                          case "SuperOps_CtaFeature": {
                            return (
                              <section className="feature-CTA">
                                <Slide delay="200">
                                  <CTA
                                    data={[field.ctaBox]}
                                    {...(field.ctaBox.type === "Card" && {
                                      ...ctaCardProps,
                                    })}
                                    {...(field.ctaBox.type === "Link" && {
                                      ...ctaLinkProps,
                                    })}
                                  />
                                </Slide>
                              </section>
                            )
                          }
                        }
                      })}
                    </section>
                    <CTAThankYou />
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default FeatureDetailsV1
